import { IW_THUMBNAIL_WIDTH } from "./constants";
import {
  ThumbnailBarProps,
  ThumbnailBarWrapperProps,
  ThumbnailProps,
} from "./type";
import styled from 'styled-components';

export const Root = styled("div")`
  background-color: rgba(30, 30, 30, 0.9);
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const Controls = styled("div")`
  position: absolute;
  top: calc(env(safe-area-inset-top) + 5px);
  right: calc(env(safe-area-inset-right) + 5px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 0.3s ease;
  will-change: right;
`;

export const Container = styled("div")`
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
`;

export const ElementContainer = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 100vw;
  height: calc(100vh - 130px);
`;

export const ElementWrapper = styled("div")`
  width: 100%;
  height: 90%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0;
  user-select: none;
  opacity: 1;
  transform: none;
`;

export const MainImage = styled("img")`
  background: transparent;
  border: 0;
  position: relative;
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  outline: none;
  user-select: none;
  transition: all 200ms ease;
  opacity: 1;
  margin: auto;
  z-index: 9997;
  box-shadow: none;
  //cursor: zoom-in;
`;

export const CaptionContainer = styled("div")`
  color: white;
  font-family: inherit;
  outline: none;
  border: 0;
  position: relative;
  z-index: 9996;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  //padding: 20px 0 30px 0;

  p {
    margin: 0;
    text-align: center;
  }
`;

export const ThumbnailBarWrapper = styled("div")<ThumbnailBarWrapperProps>`
  display: flex;
  color: white;
  height: auto;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  opacity: 1;
  transition: 0.3s ease;
  will-change: transform, opacity;
  position: relative;
  z-index: 9997;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  ${({ centered }) => {
    return `justify-content: ${centered ? "center" : "start"};`;
  }}
`;

export const ThumbnailBar = styled("div")<ThumbnailBarProps>`
  display: flex;
  height: auto;
  transition: 0.3s ease;
  will-change: transform, opacity;
  ${({ shift }) => {
    return `transform: translateX(${shift}px);`;
  }}
`;

export const Thumbnail = styled("a")<ThumbnailProps>`
  width: ${IW_THUMBNAIL_WIDTH}px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 1px;
  transition: 0.3s ease;
  will-change: opacity;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
  ${({ active }) => {
    if (active) {
      return "opacity: 1;";
    }

    return "";
  }}

  ${({ src }) => {
    if (src !== "") {
      return `background-image: url(${src})`;
    }

    return "";
  }}
`;
