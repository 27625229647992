import React, {FC, ReactChild, useMemo} from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import {HobbiesDetailPropsType} from './type';
import { PageLayout } from '../PageLayout';
import { Container } from '../Container';
import { Root, TwoColumnRoot, Left, Right, ImageGalleryImageWrapper, ImageGalleryImage } from './style';
import { HobbiesDetailSide } from './HobbiesDetailSide';
import { Link } from '../Link';
import { SRLWrapper } from 'simple-react-lightbox';
import { lightBoxOptions } from '../../lib/util';
import {PageOffset} from "../PageOffset";
import {Typography} from "../Typography";
import {ItemList} from "../ItemList";
import {HobbiesEntry} from "../../type";
import {HobbyContainer} from "../HobbyContainer";
import {MDXComponents} from "../Providers/MDXComponents";
import {ImageViewer} from "../ImageViewer/ImageViewer";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {useHobbiesDetail} from "./hooks/useHobbiesDetail";

/**
 * This component is for wrapping up pages that lay in the content/hobbies/ folder.
 * See gatsby-node.js for details.
 */
export const HobbiesDetail: FC<HobbiesDetailPropsType> = ({
    data,
    path,
}) => {
    const { getImageWrapperProps, getImageProps } = useHobbiesDetail();

    const { mdx } = data ?? {};
    const frontmatter = mdx?.frontmatter as HobbiesEntry;
    const title = frontmatter?.title ?? "";

    const location = useMemo(() => ({ pathname: path }), [path]);

    let year = 0;
    const dateSplit = (frontmatter?.date ?? "").split("-");
    if (dateSplit.length === 3) {
        year = parseInt(dateSplit[0])
    }

    const learned = useMemo(() => {
        return frontmatter?.learned?.map(item => ({
            text: item,
        })) ?? [];
    }, [frontmatter]);
    const tutorials = frontmatter?.tutorials ?? [];

    const software = frontmatter?.software ?? [];

    return (
        <PageLayout pageContext={mdx} location={location}>
            <Root>
                <TwoColumnRoot container spacing={6}>
                    <Left item md={4} sm={12}>
                        <HobbiesDetailSide data={mdx} />
                    </Left>
                    <Right item md={8} sm={12}>
                        <Container>
                            <Typography
                                variant="h1"
                                component="h1"
                                enableVerticalGutter
                            >
                                {title}
                            </Typography>
                        </Container>
                        {
                            (year !== 0)
                            &&
                            <HobbyContainer>
                                <Typography variant="h4" component="span">
                                    Year: {year}
                                </Typography>
                            </HobbyContainer>
                        }
                        {
                            software.length > 0
                            &&
                            <HobbyContainer>
                                <Typography variant="h4" component="span">
                                    Software:{' '}
                                    {
                                        software.map(((item, index) => {
                                            let text: ReactChild = item.name;
                                            if (item.version) {
                                                text = `${text} ${item.version}`;
                                            }

                                            if (item.url) {
                                                text = (<Link href={item.url}>{text}</Link>);
                                            }

                                            if (index != software.length - 1) {
                                                text = <span>{text}, {' '}</span>;
                                            }

                                            return text;
                                        }))
                                    }
                                </Typography>
                            </HobbyContainer>
                        }
                        <div>
                            <MDXProvider components={MDXComponents}>
                                <MDXRenderer pageContext={mdx}>{mdx.body}</MDXRenderer>
                            </MDXProvider>
                            {
                                learned.length > 0
                                &&
                                <HobbyContainer>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        enableVerticalGutter
                                    >
                                        Learned this time
                                    </Typography>
                                    <ItemList
                                        items={learned}
                                    />
                                </HobbyContainer>
                            }
                            {
                                tutorials.length > 0
                                &&
                                <HobbyContainer>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        enableVerticalGutter
                                    >
                                        Tutorials used
                                    </Typography>
                                    <ItemList
                                        markerEmoji="👉"
                                        items={tutorials}
                                    />
                                </HobbyContainer>
                            }
                            <HobbyContainer>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    enableVerticalGutter
                                >
                                    Images
                                </Typography>
                            </HobbyContainer>
                            <ImageViewer>
                                <HobbyContainer>
                                    <Grid container spacing={5}>
                                        {frontmatter.images.filter(image => !image.isCover).map((image, index) => {
                                            return (
                                                <Grid md={6} sm={6} xs={12} key={index} component="div">
                                                    <ImageGalleryImageWrapper
                                                        {...getImageWrapperProps(image)}
                                                    >
                                                        <ImageGalleryImage {...getImageProps(image)} />
                                                    </ImageGalleryImageWrapper>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </HobbyContainer>
                            </ImageViewer>
                            <Container>
                                <Box marginTop={5}>
                                    <Link
                                        to={
                                            mdx.frontmatter.published
                                                ? '/hobbies'
                                                : '/drafts/hobbies'
                                        }
                                    >
                                        &larr; Back to list
                                    </Link>
                                </Box>
                            </Container>
                            <PageOffset />
                        </div>
                    </Right>
                </TwoColumnRoot>
            </Root>
        </PageLayout>
    );
};

export const HobbiesDetailQuery = graphql`
    query HobbiesDetailQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                keywords
                description
                displayPageTitle
                published
                date
                updatedAt
                learned
                tutorials {
                    url
                    text
                }
                software {
                    name
                    version
                    url
                }
                images {
                    isCover
                    galleryId
                    author
                    source
                    sourceText
                    title
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 3000
                                quality: 80
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default HobbiesDetail;
