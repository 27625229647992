import {BlogRecordImageType} from "../../../type";

export const useHobbiesDetail = () => {
	return {
		getImageProps: (image: BlogRecordImageType) => {
			return {
				image: image.image!.childImageSharp.gatsbyImageData,
				alt: "",
				className: "image-viewer-image",
			};
		},
		getImageWrapperProps: (_: BlogRecordImageType) => {
			return {
				// href: image.src,
				// className: "image-viewer-image",
				// ["data-image-viewer-image-id"]: image.id,
				// ["data-image-viewer-image-src"]: image.src,
				// ["data-image-viewer-image-thumbnail-src"]: image.thumbnailSrc,
				// ["data-image-viewer-image-original-src"]: image.originalSrc,
			};
		},
	};
};
