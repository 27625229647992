import copy from "copy-to-clipboard";
import {
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  IMAGE_ITEM_CLASS_NAME,
  IW_BACKDROP,
  IW_THUMBNAIL_PADDED_WIDTH,
  IW_THUMBNAIL_WIDTH,
} from "../constants";
import { ImageData, ImageViewerProps } from "../type";

function findParentNode(element: HTMLElement) {
  let node = element;
  while (node) {
    if (typeof node.className === "string") {
      const classNames = node.className.split(" ");
      if (classNames.includes(IMAGE_ITEM_CLASS_NAME)) {
        break;
      }
    }
    if (isImageElement(node)) {
      break;
    }

    node = node.parentNode as HTMLElement;
  }
  return node;
}

// const setLocationHash = (hash: string) => {
//   window.document.location.hash = hash;
// };

const getLocationHash = (): string => {
  if (typeof window !== "undefined") {
    return window.document.location.hash.replace("#", "");
  }

  return "";
};

const readNode = (node: HTMLElement): ImageData | null => {
  let localNode = node;
  let src = localNode.getAttribute("data-image-viewer-image-src");
  if (!src) {
    if (isImageElement(localNode)) {
      src = localNode.currentSrc;
    } else {
      // last chance: find a nesting image element
      const innerImages = localNode.querySelectorAll('img');
      const innerImage = Array.from(innerImages).find(image => image.getAttribute('data-main-image') === "");
      if (innerImage) {
        localNode = innerImage;
        src = innerImage.currentSrc;
      }
    }
  }

  if (src) {
    let thumbnailSrc =
        localNode.getAttribute("data-image-viewer-image-thumbnail-src") ?? src;

    let originalSrc =
        localNode.getAttribute("data-image-viewer-image-original-src") ?? src;

    return {
      key: Math.random().toString(),
      id: localNode.getAttribute("data-image-viewer-image-id") ?? src,
      src,
      thumbnailSrc,
      originalSrc,
    };
  }

  return null;
};

const scan = (rootNode: HTMLElement): ImageData[] => {
  const result: ImageData[] = [];

  const items = rootNode.querySelectorAll(`.${IMAGE_ITEM_CLASS_NAME}`);
  items.forEach((item) => {
    const nodeData = readNode(item as HTMLElement);
    if (nodeData) {
      result.push(nodeData);
    }
  });

  return result;
};

const getThumbnailBarOffset = (
  currentItemID: string,
  images: ImageData[],
  windowWidth: number
): number => {
  if (windowWidth > images.length * IW_THUMBNAIL_PADDED_WIDTH) {
    return 0;
  }

  const currentIndex = images.findIndex((item) => item.id === currentItemID);
  if (currentIndex === -1) {
    return 0;
  }

  const imageOffset =
    (currentIndex + 1) * IW_THUMBNAIL_PADDED_WIDTH -
    Math.round(IW_THUMBNAIL_PADDED_WIDTH / 2);
  const halfScreen = Math.round(windowWidth / 2);
  const diff = imageOffset - halfScreen;
  const offset = -diff;

  if (offset > 0) {
    return 0;
  }

  const barWidth = images.length * IW_THUMBNAIL_PADDED_WIDTH;

  if (offset + barWidth < windowWidth) {
    return windowWidth - barWidth;
  }

  return offset;
};

function isImageElement(node: Node): node is HTMLImageElement {
  return node.nodeName === 'IMG';
}

export const useImageViewer = ({
  children,
}: PropsWithChildren<ImageViewerProps>) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);
  const thumbnailBarRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [images, setImages] = useState<ImageData[]>([]);
  const [currentItemID, setCurrentItemID] = useState<string>("");
  // const [hash] = useState<string>(getLocationHash());
  const [hash] = useState<string>(getLocationHash());
  const [windowWidth, setWindowWidth] = useState(0);

  const portalNode = useMemo(() => {
    if (typeof window !== "undefined") {
      return window.document.getElementsByTagName("body")[0];
    }

    return null;
  }, []);

  // keypress & hash change listening
  useEffect(() => {
    const keyPressHandler = function (event: KeyboardEvent) {
      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        const currentIndex = images.findIndex(
          (item) => item.id === currentItemID
        );
        if (currentIndex === -1) {
          return;
        }

        let nextIndex = 0;
        if (event.key === "ArrowRight") {
          nextIndex = currentIndex + 1;
          if (nextIndex >= images.length) {
            nextIndex = 0;
          }
        }
        if (event.key === "ArrowLeft") {
          nextIndex = currentIndex - 1;
          if (nextIndex < 0) {
            nextIndex = images.length - 1;
          }
        }

        const image = images[nextIndex];
        setCurrentItemID(image.id);
      }

      if (event.key === "Escape" || event.code === "Escape") {
        setCurrentItemID("");
      }
    };

    document.addEventListener("keydown", keyPressHandler);

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [currentItemID, hash, images]);

  // window width change
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const hash = getLocationHash();
    if (hash !== "") {
      setCurrentItemID(hash);
    }
  }, []);

  // item change processing
  useEffect(() => {
    if (currentItemID !== "") {
      const images = scan(rootRef.current!);
      console.log(images);
      const image = images.find((img) => img.id === currentItemID);
      if (image) {
        setImages(images);
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, [currentItemID]);

  const mainImageProps = useMemo(() => {
    const item = images.find((image) => image.id === currentItemID);
    if (item) {
      return {
        src: item.src,
        alt: "",
      };
    }

    return null;
  }, [currentItemID, images]);

  const originalImageLinkProps = useMemo(() => {
    const item = images.find((image) => image.id === currentItemID);
    if (item) {
      return {
        href: item.originalSrc,
      };
    }

    return null;
  }, [currentItemID, images]);

  return {
    scopeProps: {
      ref: rootRef,
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        if (rootRef.current === null) {
          return;
        }

        let node = findParentNode(e.target as HTMLElement);
        if (node) {
          const info = readNode(node);
          if (info?.id) {
            e.preventDefault();
            setCurrentItemID(info.id);
          }
        }
      },
    },
    closeButtonProps: {
      onClick: () => {
        setCurrentItemID("");
      },
    },
    children,
    open,
    portalNode,
    available: !!portalNode,
    images,
    getThumbnailProps: (image: ImageData) => {
      return {
        active: currentItemID !== "" && currentItemID === image.id,
        src: image.thumbnailSrc,
        onClick: () => {
          setCurrentItemID(image.id);
        },
      };
    },
    nextButtonProps: {
      onClick: () => {
        const currentIndex = images.findIndex(
          (item) => item.id === currentItemID
        );
        if (currentIndex !== -1) {
          let nextIndex = currentIndex + 1;
          if (nextIndex >= images.length) {
            nextIndex = 0;
          }

          const image = images[nextIndex];

          setCurrentItemID(image.id);
        }
      },
    },
    previousButtonProps: {
      onClick: () => {
        const currentIndex = images.findIndex(
          (item) => item.id === currentItemID
        );
        if (currentIndex !== -1) {
          let nextIndex = currentIndex - 1;
          if (nextIndex < 0) {
            nextIndex = images.length - 1;
          }

          const image = images[nextIndex];

          setCurrentItemID(image.id);
        }
      },
    },
    mainImageProps,
    imageProps: {
      ref: backdropRef,
      onClick: (event: MouseEvent<HTMLDivElement>) => {
        const node = event.target as HTMLDivElement;
        if (typeof node.className === "string") {
          const classNames = node.className.split(" ");
          if (classNames.includes(IW_BACKDROP)) {
            setCurrentItemID("");
          }
        }
      },
    },
    thumbnailBarWrapperProps: {
      centered: windowWidth > images.length * IW_THUMBNAIL_WIDTH,
    },
    thumbnailBarProps: {
      ref: thumbnailBarRef,
      shift: getThumbnailBarOffset(currentItemID, images, windowWidth),
    },
    originalImageLinkProps,
    copyImageLinkProps: {
      href: "",
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        const link = `${window.location.href}#${currentItemID}`;
        copy(link);
        e.preventDefault();
      },
    },
  };
};
