import React, { FunctionComponent, useMemo } from 'react';

import {
    Root,
    Cover,
    CoverContainer,
} from './style';
import { BlogPostHeaderProps } from './type';
import { BlogRecordImageType } from '../../../type';

export const HobbiesDetailSide: FunctionComponent<BlogPostHeaderProps> = ({
    data,
}) => {
    const headerImage = useMemo<BlogRecordImageType | undefined>(() => {
        return (data.frontmatter.images ?? []).find(
            (image: any) => image.isCover,
        );
    }, [data]);

    return (
        <Root>
            {!!(headerImage && headerImage.image) && (
                <CoverContainer>
                    <Cover
                        image={
                            headerImage.image.childImageSharp
                                .gatsbyImageData
                        }
                        alt="Avatar"
                    />
                </CoverContainer>
            )}
        </Root>
    );
};
