import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { ParagraphProps } from "./type";

export const ParagraphRoot = styled(Typography)<ParagraphProps>`
  ${({ muted, inverted, theme }) => {
    if (muted) {
      return `color: ${theme.palette.text.secondary};`;
    }

    if (inverted) {
      return "color: var(--joy-palette-background-header);";
    }

    return "";
  }}

  ${({ gutters, theme }) => {
    if (gutters) {
      return `
        margin-block-start: ${theme.spacing(2)};
        margin-block-end: ${theme.spacing(2)};
      `;
    }

    return "";
  }}
`;
