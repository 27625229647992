import { align } from '@gannochenko/etc';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

export const Root = styled.div`
    position: relative;
    height: 100%;
    ${({ theme }) => css`
        ${theme.breakpoints.down('sm')} {
            display: none;
        }
    `};
`;

export const CoverContainer = styled.div`
    ${align('center', 'center')};
    position: relative;
    height: 100%;
`;

export const Cover = styled(GatsbyImage)`
    height: 100%;
    user-select: none;
    position: fixed;
    top: 0;
`;
