import { ButtonProps } from "./type";
import styled from 'styled-components';

export const ButtonRoot = styled("button")<ButtonProps>`
  height: 40px;
  width: 40px;
  background-color: rgba(30, 30, 36, 0.8);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  visibility: inherit;
  z-index: 9998;
  opacity: 1;
  transition: opacity 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  ${({ absolute }) => {
    return {
      position: absolute ? "absolute" : "relative",
    };
  }}

  ${({ vAlignCenter }) => {
    if (vAlignCenter) {
      return "top: calc(50% - 50px);";
    }

    return "";
  }}

  ${({ right }) => {
    if (right) {
      return `
          right: calc(env(safe-area-inset-right) + 5px);
          will-change: right;
      `;
    }

    return "";
  }}

  ${({ left }) => {
    if (left) {
      return `
          left: calc(env(safe-area-inset-left) + 5px);
          will-change: left;
      `;
    }

    return "";
  }}
  
  div {
    height: 40px;
    width: 40px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  &:hover {
    path {
      fill: rgba(255, 255, 255, 1);
    }
  }
`;

export const Icon = styled("svg")`
  display: block;
  height: 100%;
  width: 100%;
  overflow: visible;
  position: relative;

  path {
    transition: fill 0.3s ease;
    fill: rgba(255, 255, 255, 0.8);
  }
`;
