import React, { PropsWithChildren } from "react";
import { ButtonProps } from "./type";
import { ButtonRoot } from "./style";

export { Icon } from "./style";

export const Button = ({
  children,
  ...restProps
}: PropsWithChildren<ButtonProps>) => {
  return (
    <ButtonRoot {...restProps}>
      <div>{children}</div>
    </ButtonRoot>
  );
};
