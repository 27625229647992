import React from 'react';
import { Link } from "../Link";
import { Paragraph } from "../Paragraph";
import { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import { Button, Icon } from "./Button/Button";
import { useImageViewer } from "./hooks/useImageViewer";
import {
  CaptionContainer,
  Container,
  Controls,
  ElementContainer,
  ElementWrapper,
  MainImage,
  Root,
  Thumbnail,
  ThumbnailBar,
  ThumbnailBarWrapper,
} from "./style";
import { ImageViewerProps } from "./type";

export const ImageViewer = (props: PropsWithChildren<ImageViewerProps>) => {
  const {
    scopeProps,
    children,
    open,
    closeButtonProps,
    portalNode,
    available,
    images,
    getThumbnailProps,
    nextButtonProps,
    previousButtonProps,
    mainImageProps,
    imageProps,
    thumbnailBarProps,
    thumbnailBarWrapperProps,
    originalImageLinkProps,
    // copyImageLinkProps,
  } = useImageViewer(props);

  return (
    <>
      <div {...scopeProps}>{children}</div>
      {open &&
        available &&
        ReactDOM.createPortal(
          <Root {...imageProps} className="iw-backdrop">
            <div className="iw-backdrop">
              <Controls>
                <Button title="Close" {...closeButtonProps}>
                  <Icon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="11 11 30 30"
                  >
                    <path d="M27.92 25l8.84-8.84 1.82-1.82c.27-.27.27-.71 0-.97l-1.95-1.95a.682.682 0 0 0-.97 0L25 22.08 14.34 11.42a.682.682 0 0 0-.97 0l-1.95 1.95c-.27.27-.27.71 0 .97L22.08 25 11.42 35.66c-.27.27-.27.71 0 .97l1.95 1.95c.27.27.71.27.97 0L25 27.92l8.84 8.84 1.82 1.82c.27.27.71.27.97 0l1.95-1.95c.27-.27.27-.71 0-.97L27.92 25z" />
                  </Icon>
                </Button>
              </Controls>
              <Button
                title="Next"
                vAlignCenter
                right
                absolute
                {...nextButtonProps}
              >
                <Icon xmlns="http://www.w3.org/2000/svg" viewBox="11 11 30 30">
                  <path d="M24.53 11.36l-.44.44c-.29.29-.29.76 0 1.05l11.09 11.09H11.83c-.41 0-.75.33-.75.75v.62c0 .41.33.75.75.75h23.35L24.09 37.14c-.29.29-.29.76 0 1.05l.44.44c.29.29.76.29 1.05 0l13.11-13.11c.29-.29.29-.76 0-1.05l-13.1-13.11a.754.754 0 0 0-1.06 0z" />
                </Icon>
              </Button>
              <Button
                title="Previous"
                vAlignCenter
                left
                absolute
                {...previousButtonProps}
              >
                <Icon xmlns="http://www.w3.org/2000/svg" viewBox="11 11 30 30">
                  <path d="M25.47 38.64l.44-.44c.29-.29.29-.76 0-1.05L14.82 26.06h23.35c.41 0 .75-.33.75-.75v-.62c0-.41-.33-.75-.75-.75H14.82l11.09-11.09c.29-.29.29-.76 0-1.05l-.44-.44a.742.742 0 0 0-1.05 0L11.31 24.47c-.29.29-.29.76 0 1.05l13.11 13.11c.29.3.76.3 1.05.01z" />
                </Icon>
              </Button>
              <Container className="iw-backdrop">
                <ElementContainer className="iw-backdrop">
                  <ElementWrapper className="iw-backdrop">
                    <MainImage {...mainImageProps} />
                  </ElementWrapper>
                </ElementContainer>
                <CaptionContainer>
                  <Paragraph inverted>
                    <Link {...originalImageLinkProps}>Show original</Link>{" "}
                    {/*<Link {...copyImageLinkProps}>Copy image link</Link>*/}
                  </Paragraph>
                </CaptionContainer>
                <ThumbnailBarWrapper
                  className="iw-backdrop"
                  {...thumbnailBarWrapperProps}
                >
                  <ThumbnailBar className="iw-backdrop" {...thumbnailBarProps}>
                    {images.map((image) => {
                      return (
                        <Thumbnail
                          key={image.key}
                          {...getThumbnailProps(image)}
                        />
                      );
                    })}
                  </ThumbnailBar>
                </ThumbnailBarWrapper>
              </Container>
            </div>
          </Root>,
          portalNode!
        )}
    </>
  );
};
